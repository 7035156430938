import { RouterProvider } from 'react-router-dom';
import router from 'routes';
import ThemeCustomization from './themes';
import { AuthProvider } from './contexts/AuthContext';
import Snackbar from 'components/@extended/Snackbar';
import Notistack from 'components/third-pary/Notistack';
import { Box } from '@mui/system';

export default function App() {
  return (
    <ThemeCustomization>
      <AuthProvider>
        <>
        <Notistack>
          <RouterProvider router={router} />
          <Snackbar />
        </Notistack>
        </>
      </AuthProvider>
    </ThemeCustomization>
  );
}
